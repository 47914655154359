import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon, MenuItem, Select } from '@mui/material';

import ErrorBoxCard from '@/components/cards/ErrorBoxCard';
import FacebookLoginButton from '@/components/utility/buttons/FacebookLoginButton';
import Loading from '@/components/utility/Loading';
import useAccountContext from '@/hooks/context/useAccountContext';
import useMetaAdAccounts from '@/hooks/meta/useMetaAdAccounts';
import useMetaPages from '@/hooks/meta/useMetaPages';
import { AdvertCurrencyModel, AdvertGoalModel } from '@/models/Adverts';
import { MetaAdsFormModel, MetaAdsPagesDataModel, MetaAdsVerifyIssuesModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';
import { handleApiError } from '@/utility/api';

const MetaChooseYourPlatform = ({
  goal,
  outputCurrency,
  outputInstagramUsername,
  canStep,
  platforms,
}: {
  goal: AdvertGoalModel | undefined;
  outputCurrency: (currency: AdvertCurrencyModel) => void;
  outputInstagramUsername: (username: string) => void;
  canStep: (value: boolean) => void;
  platforms: { logo: string; name: string }[];
}) => {
  const { t } = useTranslation();

  const { account, accountId } = useAccountContext();

  const [metaAccountPages, setMetaAccountPages] = useState<MetaAdsPagesDataModel[]>([]);
  const [hasMetaAccount, setHasMetaAccount] = useState<boolean>();
  const [selectedFacebookPage, setSelectedFacebookPage] = useState<MetaAdsPagesDataModel>();
  const [issues, setIssues] = useState<MetaAdsVerifyIssuesModel[]>([]);
  const [noMetaPages, setNoMetaPages] = useState<boolean>(false);

  const [metaAccountId, setMetaAccountId] = useState<string>('');
  const [verifyLoading, setVerifyLoading] = useState<boolean>(true);

  const { setValue, watch } = useFormContext<MetaAdsFormModel>();
  const formPlatforms = watch('ad.platforms');
  const formGoal = watch('ad.goal');

  const formAdAccountId = watch('facebookAdAccount.adAccountId');
  const formFacebookPageId = watch('facebookAdAccount.facebookPageId');
  const formInstagramPageId = watch('facebookAdAccount.instagramPageId');

  const { metaAdAccounts, metaAdAccountsIsLoading, metaAdAccountsError, refetchMetaAdAccounts } = useMetaAdAccounts();
  const { metaPages, metaPagesIsLoading, metaPagesError, refetchMetaPages } = useMetaPages();

  useEffect(() => {
    if (!formGoal) return;
    if (formGoal === 'instagram-followers' || formGoal === 'facebook-likes') {
      setValue('ad.platforms', [formGoal.split('-')[0].charAt(0).toUpperCase() + formGoal.split('-')[0].slice(1)]);
    }
  }, [formGoal, setValue]);

  useEffect(() => {
    if (!metaAdAccountsError || !metaPagesError) return;
    setHasMetaAccount(false);
  }, [metaAdAccountsError, metaPagesError]);

  const setPlatformsFromGoal = useCallback(() => {
    if (!goal) return;
    switch (goal.value) {
      case 'instagram-followers': {
        setValue(
          'ad.platforms',
          platforms.filter((item) => item.name.toLowerCase() === 'instagram').map((item) => item.name)
        );
        break;
      }
      case 'facebook-likes': {
        setValue(
          'ad.platforms',
          platforms.filter((item) => item.name.toLowerCase() === 'facebook').map((item) => item.name)
        );
        break;
      }
    }
  }, [setValue, goal, platforms]);

  useEffect(() => {
    setPlatformsFromGoal();
  }, [setPlatformsFromGoal]);

  useEffect(() => {
    if (!hasMetaAccount) return;
    refetchMetaAdAccounts();
    refetchMetaPages();
  }, [hasMetaAccount, refetchMetaAdAccounts, refetchMetaPages]);

  useEffect(() => {
    if (!selectedFacebookPage) return;
    if (selectedFacebookPage.instagram_accounts.length === 0) {
      setValue('facebookAdAccount.instagramPageId', undefined);
    } else {
      setValue('facebookAdAccount.instagramPageId', selectedFacebookPage.instagram_accounts[0].id);
    }
  }, [formFacebookPageId, formInstagramPageId, selectedFacebookPage, setValue]);

  useEffect(() => {
    if (formAdAccountId) return;
    if (!metaAdAccounts || (!formPlatforms.includes('Facebook') && !formPlatforms.includes('Instagram'))) return;

    setValue('facebookAdAccount.adAccountId', metaAdAccounts.data[0].id);
    setMetaAccountId(metaAdAccounts.data[0].account_id);
    setValue('priceLocale', metaAdAccounts.data[0].currency.code);
    outputCurrency(metaAdAccounts.data[0].currency);
  }, [formAdAccountId, formPlatforms, metaAdAccounts, outputCurrency, setValue]);

  useEffect(() => {
    if (!metaPages) return;
    if (metaPages.data.length === 0) return setNoMetaPages(true);

    setMetaAccountPages(metaPages.data);

    if (formFacebookPageId) {
      setSelectedFacebookPage(metaPages.data.find((page) => page.id === formFacebookPageId));
      return;
    }

    setValue('facebookAdAccount.facebookPageId', metaPages.data[0].id);
    setSelectedFacebookPage(metaPages.data[0]);
    if (metaPages.data[0].instagram_accounts.length > 0) {
      setValue('facebookAdAccount.instagramPageId', metaPages.data[0].instagram_accounts[0].id);
      outputInstagramUsername(metaPages.data[0].instagram_accounts[0].username);
    }
  }, [formFacebookPageId, metaPages, outputInstagramUsername, setValue]);

  const resetAccounts = useCallback(() => {
    setValue('facebookAdAccount.adAccountId', undefined);
    setValue('facebookAdAccount.facebookPageId', undefined);
    setValue('facebookAdAccount.instagramPageId', undefined);
    setMetaAccountPages([]);
  }, [setValue]);

  useEffect(() => {
    if (!account) return;

    if (account.accessTokens?.filter((platform) => platform.platform === 'meta').length > 0) {
      setHasMetaAccount(true);
    } else {
      setHasMetaAccount(false);
    }
  }, [account, account?.accessTokens]);

  const handleSelectedMetaAccount = (adAccountId: string) => {
    if (!metaAdAccounts) return;

    setValue('facebookAdAccount.adAccountId', adAccountId);

    const metaAccount = metaAdAccounts.data.find((item) => item.id === adAccountId);
    if (!metaAccount) return;

    setMetaAccountId(metaAccount.account_id);

    setValue('priceLocale', metaAccount.currency.code);
    outputCurrency(metaAccount.currency);
  };

  const handleFacebookPageChange = (facebookPageId: string) => {
    setValue('facebookAdAccount.facebookPageId', facebookPageId);
    const facebookPage = metaAccountPages.find((page) => page.id === facebookPageId);
    if (!facebookPage) return;

    setSelectedFacebookPage(facebookPage);
  };

  const handleInstagramPageChange = (instagramPageId: string) => {
    setValue('facebookAdAccount.instagramPageId', instagramPageId);
    const instagramPage = metaAccountPages
      .flatMap((page) => page.instagram_accounts)
      .find((instagram) => instagram.id === instagramPageId);
    if (!instagramPage) return;

    outputInstagramUsername(instagramPage.username);
  };

  const verifyMetaAccount = useCallback(async () => {
    setVerifyLoading(true);
    try {
      if (!accountId || !metaAccountId || !formFacebookPageId) return;

      const response = await MetaAPI.verifyMetaIds({
        accountId,
        adAccountId: metaAccountId,
        facebookPageId: formFacebookPageId,
        instagramPageId: formInstagramPageId,
      });
      if (!response.data.success) {
        setIssues(response.data.issues);
        return;
      }

      setIssues([]);
    } catch (error: unknown) {
      handleApiError({ error });
    } finally {
      setVerifyLoading(false);
    }
  }, [accountId, formFacebookPageId, formInstagramPageId, metaAccountId]);

  useEffect(() => {
    verifyMetaAccount();
  }, [verifyMetaAccount]);

  useEffect(() => {
    canStep(!!(formAdAccountId && formFacebookPageId && !verifyLoading && !issues.length && formPlatforms.length > 0));
  }, [canStep, formAdAccountId, formFacebookPageId, formPlatforms.length, issues, verifyLoading]);

  return (
    <>
      <h3>{t('SOCIAL-ADS.CHOOSE-YOUR-PLATFORM')}</h3>
      <p className="text-faded mb48 mt16 mb20-lg-down">{t('SOCIAL-ADS.CHOOSE-YOUR-PLATFORM-DESCRIPTION')}</p>
      <div className="card">
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('SOCIAL-ADS.CHOOSE-YOUR-PLATFORM')}</h3>
            <p className="text-faded mt8">{t('SOCIAL-ADS.CHOOSE-YOUR-PLATFORM-DESCRIPTION-2')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down">
            {platforms.map((item, index) => (
              <div
                key={item.name}
                className={`card-inner d-flex gap20 cursor-pointer br16 p16 ${
                  index === 0 ? 'mb8' : ''
                } ${formPlatforms.includes(item.name) ? 'chosen-goal' : ''}`}
                onClick={() => {
                  if (formPlatforms.includes(item.name)) {
                    setValue(
                      'ad.platforms',
                      formPlatforms.filter((platform) => platform !== item.name)
                    );
                    resetAccounts();
                  } else {
                    setValue('ad.platforms', [...formPlatforms, item.name]);
                  }
                }}
              >
                <div className="mt-auto mb-auto">
                  <img className="platform-icon" src={`/images/logos/${item?.logo}-logo-white.svg`} alt="" />
                </div>
                <div className="mt-auto mb-auto">
                  <p>{item?.name}</p>
                </div>
                <div className="mt-auto mb-auto ml-auto">
                  {!formPlatforms.includes(item.name) ? (
                    <Icon className="material-symbols-outlined text-faded">circle</Icon>
                  ) : (
                    <Icon className="">check_circle</Icon>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex form-divider mt10 mb10">
          <div className="line"></div>
        </div>
        <div className="d-flex gap20 mt20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('SOCIAL-ADS.CHOOSE-YOUR-ACCOUNT')}</h3>
            <p className="text-faded mt8">{t('SOCIAL-ADS.CHOOSE-YOUR-ACCOUNT-DESCRIPTION')}</p>
          </div>
          {formPlatforms.some((platform) => platform === 'Facebook' || platform === 'Instagram') && (
            <div className="flex-w60p w100p-lg-down">
              {!hasMetaAccount && (
                <div className="text-left">
                  <FacebookLoginButton />
                </div>
              )}
              {hasMetaAccount && (
                <div className="card-inner text-left p16">
                  <p className="fw-bold">{t('SOCIAL-ADS.SELECT-FACEBOOK-AD-ACCOUNT')}</p>
                  <p className="text-faded small">{t('SOCIAL-ADS.SELECT-FACEBOOK-AD-ACCOUNT-DESCRIPTION')}</p>
                  {metaAdAccountsIsLoading ? (
                    <Loading size="small" />
                  ) : (
                    <>
                      {metaAdAccounts && metaAdAccounts.data && metaAdAccounts.data.length > 1 && (
                        <div className="mt20">
                          <Select
                            className="meta-select"
                            value={formAdAccountId}
                            defaultValue={metaAdAccounts.data[0].id}
                            onChange={(event) => handleSelectedMetaAccount(event.target.value)}
                          >
                            {metaAdAccounts.data.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                <div>
                                  <p className="fw-bold small pt0">{item.name}</p>
                                  <p className="text-faded small pt0">
                                    {t('COMMON.ACCOUNT-ID')}
                                    {item.id}
                                  </p>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      )}
                      {metaAdAccounts && metaAdAccounts.data && metaAdAccounts.data.length === 1 && (
                        <div className="mt20">
                          <p className="fw-bold small pt0">{metaAdAccounts.data[0].name}</p>
                          <p className="text-faded small pt0">
                            {t('COMMON.ACCOUNT-ID')}
                            {metaAdAccounts.data[0].id}
                          </p>
                        </div>
                      )}
                      {verifyLoading ? (
                        <div className="d-flex flex-d-col gap8">
                          <p className="small mt-auto text-center mb-auto pt-0">Checking account status</p>
                          <Loading size="extra-small" noText={true} />
                        </div>
                      ) : (
                        issues &&
                        issues.length > 0 &&
                        issues.map((issue) => (
                          <div key={issue.title}>
                            <p className="text-error small">{issue.title}</p>
                            <p className="text-faded small">{issue.message}</p>
                          </div>
                        ))
                      )}
                    </>
                  )}
                </div>
              )}

              {hasMetaAccount && (
                <div className="card-inner text-left p16 mt8">
                  <p className="fw-bold">{t('SOCIAL-ADS.SELECT-FACEBOOK-PAGE')}</p>
                  <p className="text-faded small">{t('SOCIAL-ADS.SELECT-FACEBOOK-PAGE-DESCRIPTION')}</p>
                  {noMetaPages && (
                    <div className="text-left mt8">
                      <ErrorBoxCard
                        errors={[
                          { errorMessage: t('SOCIAL-ADS.NO-FACEBOOK-PAGES'), propertyName: '', propertyValue: '' },
                        ]}
                      />
                      <Button
                        onClick={() => {
                          window.open('https://www.unhurdmusic.com/blog/facebook-ad-account-setup');
                        }}
                        className="btn-black-2 w100p ml0 mt8"
                      >
                        {t('SOCIAL-ADS.CONNECT-YOUR-FACEBOOK-PAGE')}
                      </Button>
                    </div>
                  )}
                  {metaPagesIsLoading ? (
                    <Loading size="small" />
                  ) : (
                    <>
                      {metaAccountPages && metaAccountPages.length > 1 && (
                        <div className="mt20">
                          <Select
                            className="meta-select"
                            value={formFacebookPageId}
                            defaultValue={metaAccountPages[0].id}
                            onChange={(event) => handleFacebookPageChange(event.target.value)}
                          >
                            {metaAccountPages.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                <div className="d-flex gap8">
                                  <img className="social-media-profile-pics" src={item.picture.data.url} alt="" />

                                  <p className="small fw-bold mt-auto mb-auto pt0">{item.name}</p>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      )}
                      {metaAccountPages && metaAccountPages.length === 1 && (
                        <div className="mt20 d-flex gap8">
                          <div>
                            <img
                              className="social-media-profile-pics"
                              src={metaAccountPages[0].picture.data.url}
                              alt=""
                            />
                          </div>
                          <p className="small fw-bold mt-auto mb-auto pt0">{metaAccountPages[0].name}</p>
                        </div>
                      )}
                      {!metaAccountPages && <Loading size="small" />}
                    </>
                  )}
                </div>
              )}
              {hasMetaAccount && !noMetaPages && (
                <div className="card-inner text-left p16 mt8">
                  <p className="fw-bold">{t('SOCIAL-ADS.SELECT-INSTAGRAM-PAGE')}</p>
                  <p className="text-faded small">{t('SOCIAL-ADS.SELECT-INSTAGRAM-PAGE-DESCRIPTION')}</p>
                  {metaPagesIsLoading ? (
                    <Loading size="small" />
                  ) : (
                    <>
                      {selectedFacebookPage && selectedFacebookPage.instagram_accounts.length > 1 && (
                        <div className="mt20">
                          <Select
                            className="meta-select"
                            value={formInstagramPageId}
                            defaultValue={selectedFacebookPage.instagram_accounts[0].id}
                            onChange={(event) => handleInstagramPageChange(event.target.value)}
                          >
                            {selectedFacebookPage.instagram_accounts.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                <div className="d-flex gap8">
                                  <img className="social-media-profile-pics" src={item.profile_pic} alt="" />
                                  <p className="small fw-bold mt-auto mb-auto pt0">{item.username}</p>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      )}

                      {selectedFacebookPage && selectedFacebookPage.instagram_accounts.length === 1 && (
                        <div className="mt20 d-flex gap8">
                          <div>
                            <img
                              className="social-media-profile-pics"
                              src={
                                selectedFacebookPage?.instagram_accounts.length > 0
                                  ? selectedFacebookPage.instagram_accounts[0].profile_pic
                                  : '/images/profile-placeholder.svg'
                              }
                              alt=""
                            />
                          </div>
                          <p className="small fw-bold mt-auto mb-auto pt0">
                            {selectedFacebookPage?.instagram_accounts.length > 0
                              ? selectedFacebookPage.instagram_accounts[0].username
                              : ''}
                          </p>
                        </div>
                      )}

                      {selectedFacebookPage && !selectedFacebookPage.instagram_accounts.length && (
                        <div className="mt20 d-flex gap8">
                          <p className="small fw-bold mt-auto mb-auto pt0">No instagram accounts available</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MetaChooseYourPlatform;
