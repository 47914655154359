import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, CircularProgress, Icon } from '@mui/material';
import { AxiosError } from 'axios';
import { addDays, addHours, setHours, setMinutes } from 'date-fns';

import StripeCheckout from '@/components/payments/StripeCheckout';
import Loading from '@/components/utility/Loading';
import NumberStepper from '@/components/utility/microcomponents/NumberStepper';
import DialogModal from '@/components/utility/modals/DialogModal';
import { metaCurrencyCalculations, metaGoalPlatforms, metaGoals } from '@/constants/Advert';
import { Products, UnhurdProductPrices } from '@/constants/ProductList';
import { PromoteFlowStepFormatter } from '@/formatters/PromoteFlowStepFormatter';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useSubscription from '@/hooks/account/useSubscription';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useMetaPotentialReach from '@/hooks/meta/useMetaPotentialReach';
import useUserTracking from '@/hooks/useUserTracking';
import useScrollToTop from '@/hooks/utility/useScrollToTop';
import { AdvertCurrencyCalculationsModel, AdvertCurrencyModel, AdvertGoalModel } from '@/models/Adverts';
import { MetaAdsFormModel, MetaErrorModel, MetaErrorValidationErrorModel, PredeterminedLocations } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';
import { handleApiError } from '@/utility/api';
import { getNextQuarterHour } from '@/utility/date';

import BudgetAndDuration from '../generic-ads-steps/BudgetAndDuration';
import SetYourGoal from '../generic-ads-steps/SetYourGoal';
import MetaAdSummary from './meta-ads-steps/MetaAdSummary';
import MetaAudienceTargeting from './meta-ads-steps/MetaAudienceTargeting';
import MetaChooseYourPlatform from './meta-ads-steps/MetaChooseYourPlatform';
import MetaDesignYourAds from './meta-ads-steps/MetaDesignYourAds';

enum MetaFlowPages {
  GOAL = 1,
  PLATFORM = 2,
  BUDGET = 3,
  AUDIENCE = 4,
  DESIGN = 5,
  SUMMARY = 6,
}

enum MetaFlowPagesShort {
  PLATFORM = 1,
  BUDGET = 2,
  AUDIENCE = 3,
  DESIGN = 4,
  SUMMARY = 5,
}

const MetaAdsModal = ({ closeModalOutput }: { closeModalOutput: () => void }) => {
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { scrollToTopInPromoteFlows } = useScrollToTop();
  const userTracking = useUserTracking();
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const [pages, setPages] = useState<typeof MetaFlowPages | typeof MetaFlowPagesShort>(MetaFlowPages);

  const { dispatchSnackbar } = useSnackbarContext();
  const { account, accountId } = useAccountContext();
  const { isSubscribed } = useSubscription();

  const [step, setStep] = useState<number>(MetaFlowPages.GOAL);

  const [canStep, setCanStep] = useState(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [goal, setGoal] = useState<AdvertGoalModel>();
  const [currencyOffset, setCurrencyOffset] = useState<number>(0);
  const [currencyCalculations, setCurrencyCalculations] = useState<AdvertCurrencyCalculationsModel>();
  const [draftCampaignId, setDraftCampaignId] = useState<string>();
  const [loadPayment, setLoadPayment] = useState<boolean>(false);
  const [disablePay, setDisablePay] = useState<boolean>(false);
  const [instagramUsername, setInstagramUsername] = useState<string>('');
  const [selectedFacebookMediaUrl, setSelectedFacebookMediaUrl] = useState<string>();
  const [errors, setErrors] = useState<MetaErrorValidationErrorModel[]>();

  const [predeterminedLocations, setPredeterminedLocations] = useState<PredeterminedLocations>(
    PredeterminedLocations.PRIMARY
  );

  const socialAd = useMemo(() => {
    return {
      productType: 'Social Ads',
      priceId: '',
      platform: 'web',
      orderDetails: {
        totalValue: null,
        totalQuantity: 1,
        currency: null,
        products: [
          {
            quantity: 1,
            productID: 'meta_ads_001',
            price: null,
            name: 'Social Ads',
            currency: null,
          },
        ],
      },
    };
  }, []);

  const sendInitiateFlow = useCallback(() => {
    if (userTracking && socialAd) return userTracking.userInitiatedProFeature(socialAd, 'Social Ads');
  }, [socialAd, userTracking]);

  const sendCancelFlow = useCallback(() => {
    if (userTracking && socialAd) return userTracking.userCancelledProFeature(socialAd, 'Social Ads');
  }, [socialAd, userTracking]);

  const sendPublishFlow = useCallback(() => {
    if (userTracking && socialAd) return userTracking.userPublishProFeature(socialAd, 'Social Ads');
  }, [socialAd, userTracking]);

  useEffect(() => {
    sendInitiateFlow();
  }, [sendInitiateFlow]);

  const todoGoal = useMemo(() => params.get('todoGoal'), [params]);

  const NUMBER_OF_STEPS = Object.keys(pages).length / 2;
  const DEFAULT_TOTAL_DAYS = 13;

  const defaultValues = {
    accountId: undefined,
    ad: {
      content: {
        primaryText: '',
        link: '',
        linkHeadline: '',
        linkDescription: '',
      },
      budget: undefined,
      link: undefined,
      goal: undefined,
      message: undefined,
      currencyCode: undefined,
      callToAction: undefined,
      targeting: {
        ageMax: 34,
        ageMin: 18,
        audiences: [],
        genders: [],
        geoLocations: {
          countryGroups: [],
          countries: [],
          cities: [],
          regions: [],
        },
      },
      type: 'image',
      media: [],
      videoThumbnail: undefined,
      startTime: addHours(getNextQuarterHour(new Date()), 1).toISOString(),
      endTime: setMinutes(setHours(addDays(new Date(), DEFAULT_TOTAL_DAYS), 23), 45).toISOString(),
      platforms: [],
    },
    facebookAdAccount: {
      adAccountId: '',
      facebookPageId: '',
      instagramPageId: '',
    },
    priceLocale: '',
  };

  const formMethods = useForm<MetaAdsFormModel>({ defaultValues });
  const formPlatforms = formMethods.watch('ad.platforms');
  const formAdAccountId = formMethods.watch('facebookAdAccount.adAccountId');
  const formGenders = formMethods.watch('ad.targeting.genders');
  const formMinAge = formMethods.watch('ad.targeting.ageMin');
  const formMaxAge = formMethods.watch('ad.targeting.ageMax');
  const formAudiences = formMethods.watch('ad.targeting.audiences');
  const formCountryGroups = formMethods.watch('ad.targeting.geoLocations.countryGroups');
  const formCountries = formMethods.watch('ad.targeting.geoLocations.countries');
  const formCities = formMethods.watch('ad.targeting.geoLocations.cities');
  const formRegions = formMethods.watch('ad.targeting.geoLocations.regions');

  useEffect(() => {
    if (!accountId) return;

    formMethods.setValue('accountId', accountId);
  }, [accountId, formMethods]);

  useEffect(() => {
    if (!account) return;

    const accessToken = account.accessTokens?.find((platform) => platform.platform.includes('meta'))?.accessToken;

    if (!accessToken) return;
    formMethods.setValue('facebookAdAccount.accessToken', accessToken);
  });

  useEffect(() => {
    if (!todoGoal) return;

    formMethods.setValue('ad.goal', todoGoal);
    setPages(MetaFlowPagesShort);
    setStep(pages['PLATFORM']);
  }, [formMethods, pages, todoGoal]);

  const { potentialReach, potentialReachIsLoading } = useMetaPotentialReach({
    platforms: formPlatforms.map((platform) => platform.toLowerCase()),
    adAccountId: formAdAccountId,
    genders: formGenders,
    ageMin: formMinAge,
    ageMax: formMaxAge,
    audiences: formAudiences,
    countryGroups: formCountryGroups,
    countries: formCountries,
    cities: formCities,
    regions: formRegions,
  });

  const stepRef = useRef(step);

  const trackProductViewed = useCallback(() => {
    if (!userTracking) return;
    if (stepRef.current !== step) {
      userTracking?.productViewed?.({
        product: 'Meta Ads',
        productScreenName: PromoteFlowStepFormatter('meta', step),
        goal: t(goal?.title ?? ''),
        platforms: formPlatforms,
      });
    } else if (isInitialLoad) {
      userTracking?.productViewed?.({
        product: 'Meta Ads',
        productScreenName: PromoteFlowStepFormatter('meta', step),
      });
      setIsInitialLoad(false);
    }
  }, [userTracking, step, isInitialLoad, t, goal?.title, formPlatforms]);

  useEffect(() => {
    trackProductViewed();
  }, [trackProductViewed]);

  const formatData = useCallback(
    (formValues: MetaAdsFormModel) => ({
      ...formValues,
      ad: {
        ...formValues.ad,
        platforms: formValues.ad.platforms.map((platform) => platform.toLowerCase()),
        budget: formValues.ad.budget && formValues.ad.budget * currencyOffset,
        startTime: new Date(formValues.ad.startTime).toISOString(),
        endTime: new Date(formValues.ad.endTime).toISOString(),
      },
    }),
    [currencyOffset]
  );

  const createMetaDraft = useCallback(async () => {
    try {
      if (!accountId) return;

      const data = formMethods.getValues();

      const response = await MetaAPI.createMetaAdDraft(formatData(data));
      setDraftCampaignId(response.data.id);
      setLoadPayment(true);
    } catch (error: unknown) {
      handleApiError({ error, dispatchSnackbar });
      if (error) {
        const errs = error as AxiosError<MetaErrorModel>;
        setErrors(errs.response?.data.errors);
      }
    } finally {
      setDisablePay(false);
    }
  }, [accountId, dispatchSnackbar, formMethods, formatData]);

  const createMetaAd = useCallback(async () => {
    try {
      if (!accountId) return;

      const data = formMethods.getValues();

      await MetaAPI.createMetaAd(formatData(data));
      navigate('/payment-confirmation/meta');
    } catch (error: unknown) {
      setDisablePay(false);
      handleApiError({ error, dispatchSnackbar });
      if (error) {
        const errs = error as AxiosError<MetaErrorModel>;
        setErrors(errs.response?.data.errors);
      }
    } finally {
      sendPublishFlow();
      setDisablePay(false);
    }
  }, [accountId, dispatchSnackbar, formMethods, formatData, navigate, sendPublishFlow]);

  const handleDialogOutput = (output: boolean) => {
    if (!output) return setDialogOpen(false);
    sendCancelFlow();
    userTracking?.productExited?.({
      product: 'Meta Ads',
      productScreenName: PromoteFlowStepFormatter('meta', step),
      goal: t(goal?.title ?? ''),
      platforms: formPlatforms,
    });
    return closeModalOutput();
  };

  const handleCanStep = useCallback(
    (value: boolean) => {
      if (value !== canStep) {
        setCanStep(value);
      }
    },
    [canStep]
  );

  const outputCurrency = useCallback(
    (currency: AdvertCurrencyModel) => {
      setCurrencyOffset(currency.offset);
      const newCurrencyCalculations = metaCurrencyCalculations(currency);
      setCurrencyCalculations(newCurrencyCalculations);
      formMethods.setValue('ad.budget', newCurrencyCalculations.startPrice);
    },
    [formMethods]
  );

  const outputInstagram = useCallback((username: string) => setInstagramUsername(username), []);

  const outputSelectedFacebookMediaUrl = useCallback((url: string) => {
    setSelectedFacebookMediaUrl(url);
  }, []);

  const outputGoal = useCallback((goal?: AdvertGoalModel) => setGoal(goal), []);

  useEffect(() => {
    if (step !== pages['SUMMARY']) return setErrors(undefined);
  }, [pages, step]);

  return (
    <div className="promote-modal-container text-center">
      <NumberStepper steps={NUMBER_OF_STEPS} stepNumber={step} />
      <DialogModal
        open={dialogOpen}
        title={'DIALOGS.QUIT-META-ADS'}
        content={'DIALOGS.ARE-YOU-SURE-YOU-WANT-TO-QUIT'}
        output={(output) => handleDialogOutput(output)}
      />
      <Button className="icon-btn close-button" onClick={() => setDialogOpen(true)}>
        <Icon>close</Icon>
      </Button>
      <div className="mt48 mt20-lg-down"></div>
      <div className="w90p-lg-down w60p ml-auto mr-auto mb300">
        <FormProvider {...formMethods}>
          {pages === MetaFlowPages && step === pages['GOAL'] && (
            <SetYourGoal goals={metaGoals} outputGoal={outputGoal} canStep={handleCanStep} />
          )}
          {step === pages['PLATFORM'] && (
            <MetaChooseYourPlatform
              goal={goal}
              outputCurrency={outputCurrency}
              outputInstagramUsername={outputInstagram}
              canStep={handleCanStep}
              platforms={metaGoalPlatforms}
            />
          )}
          {step === pages['BUDGET'] && (
            <BudgetAndDuration
              currencyCalculations={currencyCalculations || metaCurrencyCalculations()}
              canStep={(canStep: boolean) => setCanStep(canStep)}
            />
          )}
          {step === pages['AUDIENCE'] && (
            <MetaAudienceTargeting
              canStep={(canStep: boolean) => setCanStep(canStep)}
              predeterminedLocations={predeterminedLocations}
              setPredeterminedLocations={setPredeterminedLocations}
            />
          )}
          {step === pages['DESIGN'] && (
            <MetaDesignYourAds
              outputSelectedFacebookMediaUrl={outputSelectedFacebookMediaUrl}
              canStep={(canStep: boolean) => setCanStep(canStep)}
            />
          )}
          {step === pages['SUMMARY'] && (
            <MetaAdSummary
              selectedFacebookMediaUrl={selectedFacebookMediaUrl}
              instagramUsername={instagramUsername}
              errors={errors}
            />
          )}
        </FormProvider>
      </div>
      <div className="promote-footer">
        <div className={`card-inner w90p p10 pl20 m-auto d-flex ${step === NUMBER_OF_STEPS ? 'to-do-card' : ''}`}>
          {potentialReachIsLoading && <Loading size="extra-small" noText={true} />}
          {potentialReach && step !== NUMBER_OF_STEPS && (
            <div className="text-left mt-auto mb-auto">
              <p className="small text-faded mb4">{t('SOCIAL-ADS.POTENTIAL-REACH')}</p>
              <p className="fw-bold">
                {numberFormatter(potentialReach.users_lower_bound)} -{' '}
                {numberFormatter(potentialReach.users_upper_bound)}
              </p>
            </div>
          )}
          {step === NUMBER_OF_STEPS && (
            <div className="text-left mt-auto mb-auto">
              <p className="small text-faded">{t('Total to pay now')}</p>
              <p className="fw-bold">£{isSubscribed ? '0.00' : UnhurdProductPrices.socialMediaBookingFee}</p>
              <p className="small text-faded pt0">{t('unhurd service fee')}</p>
            </div>
          )}
          <div className="ml-auto">
            {step > 1 && (
              <Button
                disabled={disablePay}
                className="border-btn"
                onClick={() => {
                  setStep(step > 1 ? step - 1 : step);
                  scrollToTopInPromoteFlows();
                }}
              >
                <Icon className="ml-8">chevron_left</Icon>
                {t('COMMON.BACK')}
              </Button>
            )}
            {step < NUMBER_OF_STEPS && (
              <Button
                disabled={!canStep}
                className="btn-white"
                onClick={() => {
                  setStep(step < NUMBER_OF_STEPS ? step + 1 : step);
                  scrollToTopInPromoteFlows();
                }}
              >
                {t('COMMON.CONTINUE')}
                <Icon className="mr-8">chevron_right</Icon>
              </Button>
            )}
            {step === NUMBER_OF_STEPS && (
              <Button
                disabled={disablePay}
                className="btn-white"
                onClick={() => {
                  setDisablePay(true);
                  isSubscribed ? createMetaAd() : createMetaDraft();
                }}
              >
                {disablePay ? (
                  <CircularProgress size={16} />
                ) : isSubscribed ? (
                  t('COMMON.PUBLISH')
                ) : (
                  t('PLAYLISTING.CONFIRM-AND-PAY')
                )}
              </Button>
            )}
          </div>
          {loadPayment && (
            <StripeCheckout
              product={{
                draftCampaignId: draftCampaignId,
                productType: 'social-media-ad',
                priceId: Products.metaAds.priceId,
                platform: 'meta',
                orderDetails: {
                  totalValue: isSubscribed ? 0 : UnhurdProductPrices.socialMediaBookingFee,
                  totalQuantity: 1,
                  currency: 'GBP',
                  products: [
                    {
                      quantity: 1,
                      productID: 'meta_ads_001',
                      price: isSubscribed ? 0 : UnhurdProductPrices.socialMediaBookingFee,
                      name: 'Meta Ads',
                      currency: 'GBP',
                    },
                  ],
                },
              }}
              onComplete={() => {
                setLoadPayment(false);
                setDisablePay(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MetaAdsModal;
